import React from "react";
import cloud from "../../Assets/DigitalTwin/workflow.png";

const Workflow = () => {
  return (
    <div className="flex flex-col bg-[#f4f4f4]  font-montserrat">
      <div className="h-16 w-full bg-white rounded-b-3xl"></div>
      <div className="flex flex-col md:px-16 md:my-4">
        <p className="text-lg md:text-3xl text-center md:text-start font-semibold ml-4  my-4 mb-8">
          <span className="text-[#135788] gap-2"> Delphi Digital Twin </span> -
          Workflow
        </p>
        <div className="flex gap-4 flex-col font-normal md:font-light md:text-xl text-base text-start ">
          <p className="mx-4">
            <span className="text-[#135788] font-semibold mx-2">
              Delphi Digital Twin
            </span>
            simplifies the creation of digital twins of physical devices such as
            buildings, industrial equipment, factories, and production lines for
            developers. The platform gives you the tools to create digital twins
            to help you optimize building operations, boost production, and
            enhance equipment performance. You can now employ digital twins to
            generate a comprehensive perspective of your operations faster and
            with less effort by using existing data from various sources,
            creating virtual representations of any physical location, and
            combining current 3D models with real-world data.
          </p>

          <div className="overflow-hidden relative  justify-center flex items-center md:items-">
            <img src={cloud} className="rounded-xl w-[90%] " alt="" />
          </div>
          <p className="mx-4">
            We can quickly combine with current IoT, video, and enterprise
            application data where it already exists using Delphi Digital Twin
            without having to reingest or relocate the data. Digital Twin
            Platform saves time by automatically generating a knowledge graph
            that connects your data sources to simulated hardware replicas to
            successfully model real-world ecosystems and obtain an immersive 3D
            view of your activities and infrastructure to increase production
            and optimize efficiency, and improve performance.
          </p>
        </div>
      </div>
      <div className="h-16 bg-white rounded-t-3xl"></div>
    </div>
  );
};

export default Workflow;
