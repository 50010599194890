import React from "react";
// import BlogImg from "../Assets/blogPage/Blogimg.svg";
import userImg from "../Assets/blogPage/Ellipse 176.svg";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Avatar,
  Tooltip,
} from "@material-tailwind/react";

const BlogCard = ({ article }) => {
  const getFirstImage = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const imgElement = doc.querySelector("img");
    return imgElement ? imgElement.src : null;
  };

  const getDescriptionWithoutImages = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const imgElements = doc.querySelectorAll("img");
    imgElements.forEach((img) => {
      img.parentNode.removeChild(img);
    });
    return doc.body.innerHTML;
  };

  // Get the first image URL from the article description
  const firstImageUrl = getFirstImage(article.description);

  // Get the description without images
  const descriptionWithoutImages = getDescriptionWithoutImages(
    article.description
  );
  return (
    <div>
      {/* <div className="bg-blogBorder bg-no-repeat bg-center bg-contain   "> */}
      <a
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        href={`/blog/${article.guid.split("/").pop()}`}
      >
        <Card className="md:max-w-[24rem] w-[22rem] overflow-hidden capabiltitescard text-[#2F2F2F]">
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 rounded-none"
          >
            <img
              src={firstImageUrl}
              alt="ui/ux review check"
              className="w-full h-60"
            />
          </CardHeader>
          <CardBody className="p-4">
            <Typography variant="h4" color="blue-gray" className="font-nexa">
              {article.title.substring(0, 30)}...
            </Typography>
            {/* <Typography variant="lead" color="gray" className="mt-3 font-normal" >
          Because it&apos;s about motivating the doers. Because I&apos;m here to
          follow my dreams and inspire others.
        </Typography> */}
            <div
              dangerouslySetInnerHTML={{
                __html: descriptionWithoutImages,
              }}
              className="font-normal line-clamp-2 font-montserrat"
            />
          </CardBody>
          <CardFooter className="flex items-center justify-between px-4 pb-4">
            <div className="flex items-center gap-4">
              <img src={userImg} alt="" className="h-10 w-10" />
              <div className="font-montserrat">{article.author}</div>
            </div>
            <Typography className="font-normal font-montserrat">
              {" "}
              {new Date(article.pubDate).toLocaleDateString()}
            </Typography>
          </CardFooter>
        </Card>
      </a>
    </div>
    // </div>
  );
};

export default BlogCard;
