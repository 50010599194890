import React from "react";
import cloud from "../../Assets/DataCapture/Overview - Delphi Data Capture.jpg";

const Overview = () => {
  return (
    <div className="flex flex-col mx-16 font-montserrat">
      <div className="flex flex-col">
        <p className="text-lg md:text-3xl text-center md:text-start font-semibold  my-4 mb-8">
          Overview -<span className="text-[#135788] gap-2">Delphi </span> Data
          Capture
        </p>
        <div className="flex gap-4 flex-col font-normal md:font-light md:text-xl text-base text-center ">
          <p className="">
            <span className="text-[#135788] font-semibold gap-2">Delphi </span>{" "}
            Behavioral Data Platform empowers you to oversee and leverage
            valuable, first-party behavioral data straight from your cloud data
            warehouse or data lake. This enables precise forecasting of customer
            behaviors, personalized customer experiences, and real-time
            detection of fraudulent activities.
          </p>
          <div className="overflow-hidden h-32 md:h-96 rounded-xl flex items-center md:items-">
            <img src={cloud} className="rounded-xl" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
