import React from "react";
import HeadImg from "../Assets/DataCapture/Self Managed Data Capture.jpg";
import Overview from "../Components/DataCaptureComponents/Overview";
import Possible from "../Components/DataCaptureComponents/Possible";
import Offerings from "../Components/DataCaptureComponents/Offerings";
import Reviews from "../Components/IndexComponents/Reviews";
import MediumArticles from "./Admin/MediumArticles";
import Explore from "../Components/DataCaptureComponents/Explore";

const DataCapturePage = () => {
  return (
    <div className="flex flex-col gap-4 md:gap-2 mt-12 font-montserrat">
      <div className=" relative z-20">
        <div className="absolute top-0 w-full h-full bg-gradient-to-b from-transparent via-transparent to-[#fff]  flex flex-col items-center justify-center z-20 "></div>
        <div className="flex flex-col   absolute z-20 top-8 left-4 md:left-16 md:top-16">
          <h1 className="w-1/2 text-[22px] font-bold  lg:text-xl  gap-2  md:my-0 md:mx-0  font-nexa text-[#135788]">
            Self Managed
          </h1>
          <h1 className="w-1/2 text-lg font-bold  lg:text-4xl  gap-2  md:my-0 md:mx-0  font-nexa text-[#2F2F2F]">
            Data Capture
          </h1>
          <p className="w-1/2 font-medium my-1">
            Unlock the value of your customer behavioral data to fuel next-gen
            AI, analytics, and marketing
          </p>
        </div>
        <div className="w-full max-h-screen overflow-hidden relative z-10">
          <img className="object-cover " src={HeadImg} alt="" />
        </div>
      </div>
      <div className="flex flex-col gap-10 ">
        <Overview />

        <Possible />
        <Explore />
        <div className="mx- ">
          <Offerings />
        </div>
      </div>
      <Reviews />
      <MediumArticles />
    </div>
  );
};

export default DataCapturePage;
