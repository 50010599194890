import React from "react";
import model from "../../Assets/DigitalTwin/Core Features - Model Builder.jpg";
import data from "../../Assets/DigitalTwin/Core Feature - Datra Connectors.jpg";
import scene from "../../Assets/DigitalTwin/Core Features - Scene Composer.jpg";
import app from "../../Assets/DigitalTwin/Core Feature - App Toolkit.jpg";

const Features = () => {
  return (
    <div className="flex flex-col bg-[#f4f4f4] font-montserrat">
      <div className="h-16 bg-white rounded-b-3xl"></div>
      <div className="flex flex-col items-center md:mx-12 md:my-8">
        <p className="text-lg md:text-3xl text-center md:text-center font-semibold ml-4 md:mx-20 my-4 ">
          <span className="text-[#135788] gap-2">Core </span> Features
        </p>
        <p className="text-center text-xs md:text-lg font-medium mx-16 md:mx-2  text-[#00000099] font-montserrat w-[60%] mb-4">
          <span className="font-bold ">Digital Twin Platform </span> is a
          powerful tool that can be used to glean valuable insights from an
          immersive interactive environment designed for your usage
        </p>
        <div className="flex flex-col md:flex-row  mx-14 md:mx-36 md:my-12 font-normal md:font-light md:text-lg text-base text-start md:w-[90%] gap-8">
          <div className="text-center flex flex-col  gap-4  justify-center md:w-1/4">
            <div className="flex justify-center h-40  overflow-hidden ">
              <img className="w-[12rem] rounded-xl" src={model} alt="" />
            </div>

            <h1 className="font-bold text-base">Model Builder</h1>
            <p className="text-sm mx-10">
              Create entities to virtually represent physical systems, specify
              relationships between them
            </p>
          </div>
          <div className="text-center flex flex-col  gap-4  justify-cente md:w-1/4 relative top-2">
            <div className="flex justify-center h-40  overflow-hidden ">
              <img className="w-[12rem] rounded-xl" src={data} alt="" />
            </div>

            <h1 className="font-bold text-base">Data Connectors</h1>
            <p className="text-sm mx-10">
              Access data from diverse sources using built-in or by creating
              your own connectors to self and third-party data sources.
            </p>
          </div>
          <div className="text-center flex flex-col  gap-4  justify-center md:w-1/4">
            <div className="flex justify-center h-40  overflow-hidden">
              <img className="w-[12rem] rounded-xl" src={scene} alt="" />
            </div>

            <h1 className="font-bold text-base">Scene Composer</h1>
            <p className="text-sm mx-10">
              Combine existing 3D visual models with real- world data to compose
              an interactive 3D view of your environment
            </p>
          </div>
          <div className="text-center flex flex-col  gap-4  justify-center md:w-1/4 ">
            <div className="flex justify-center h-40  overflow-hidden ">
              <img className="w-[12rem] rounded-xl" src={app} alt="" />
            </div>
            <h1 className="font-bold text-base">App ToolKit</h1>
            <p className="text-sm mx-10">
              Create entities to virtually represent physical systems, specify
              relationships between them
            </p>
          </div>
        </div>
      </div>
      <div className="h-16 bg-white rounded-t-3xl"></div>
    </div>
  );
};

export default Features;
