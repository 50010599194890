import React, { useState } from "react";
import beha from "../../Assets/DataCapture/Explore More - Behavioural Insight Hub.jpg";
import gove from "../../Assets/DataCapture/Explore More - Data Governance.jpg";
import prod from "../../Assets/DataCapture/Explore More - Data Product Leadership.jpg";
import full from "../../Assets/DataCapture/Explore More - Fulfilment.jpg";
import icon4 from "../../Assets/DataCapture/icons/data (4).svg";
import icon6 from "../../Assets/DataCapture/icons/data (6).svg";
import icon8 from "../../Assets/DataCapture/icons/data (8).svg";
import icon9 from "../../Assets/DataCapture/icons/data (9).svg";
import icon10 from "../../Assets/DataCapture/icons/data (10).svg";
import icon11 from "../../Assets/DataCapture/icons/data (11).svg";
import icon12 from "../../Assets/DataCapture/icons/data (12).svg";
import icon14 from "../../Assets/DataCapture/icons/data (14).svg";
import icon15 from "../../Assets/DataCapture/icons/data (15).svg";
import icon16 from "../../Assets/DataCapture/icons/data (16).svg";
import icon18 from "../../Assets/DataCapture/icons/data (18).svg";
import icon19 from "../../Assets/DataCapture/icons/data (19).svg";
import icon20 from "../../Assets/DataCapture/icons/data (20).svg";
import icon21 from "../../Assets/DataCapture/icons/data (21).svg";
import icon31 from "../../Assets/DataCapture/icons/data (31).svg";
import icon32 from "../../Assets/DataCapture/icons/data (32).svg";

const Explore = () => {
  const [tab, setTab] = useState("beh");
  return (
    <>
      <div className="flex flex-col relative items-center  md:items-start  lg:items-center  md:my-4 overflow-hidden">
        <h1 className="font-semibold text-xl md:text-3xl text-center my-2 w-full font-nexa ">
          <span className="text-[#145899]">Explore </span> More
        </h1>
        <h1 className="font-normal text-base md:text-3xl text-center my-2 w-3/4 md:w-full font-nexa ">
          A first-party, privacy-compliant behavioral data platform
        </h1>
        <div className="flex justify-between my-4 w-[80%] md:w-[93%] md:my-8 md:h-10  transition-none gap-4  ">
          <div
            onClick={() => setTab("beh")}
            className={`py-1 px-1 text-xs md:text-xl rounded text-center cursor-pointer  ${
              tab === "beh" ? " border-b-4  border-[#145899]" : ""
            } `}
          >
            Behavioral Insights Hub
          </div>
          <div
            onClick={() => setTab("gov")}
            className={`py-1 px-1 text-xs md:text-xl rounded text-center cursor-pointer  ${
              tab === "gov" ? " border-b-4  border-[#145899]" : ""
            } `}
          >
            Data Governance
          </div>
          <div
            onClick={() => setTab("pro")}
            className={`py-1 px-1 text-xs md:text-xl rounded text-center cursor-pointer  ${
              tab === "pro" ? " border-b-4  border-[#145899]" : ""
            } `}
          >
            Data Product Leadership
          </div>
          <div
            onClick={() => setTab("ful")}
            className={`py-1 px-1 text-xs md:text-xl rounded text-center cursor-pointer  ${
              tab === "ful" ? " border-b-4  border-[#145899]" : ""
            } `}
          >
            Fulfillment
          </div>
        </div>
        {tab === "beh" ? (
          <div className="flex my-2 ">
            <div className="w-1/2">
              <p className="text-sm md:text-2xl mx-8 md:mx-16 h-24">
                A privacy-compliant platform harnessing first-party behavioral
                data.
              </p>
              <div className="bg-[#f4f4f4] w-[150%] grid grid-cols-1 md:grid-cols-2 mt-10 px-4 py-6 md:py-10 md:px-16 gap-y-10 rounded-r-xl">
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon6} alt="" />
                  <p className="text-lg">
                    Safely distribute, oversee, and refine data via both the
                    console and APIs
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon20} alt="" />
                  <p className="text-lg">
                    Identify and rectify data quality issues using structured
                    workflow
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon9} alt="" />
                  <p className="text-lg">
                    Employ tools for automatic notification of emerging data
                    quality concerns
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon4} alt="" />
                  <p className="text-lg">
                    Establish and supervise your data pipeline through an
                    intuitive user interface
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/2 flex items-center justify-center">
              <img
                className="md:w-1/2 rounded-xl relative bottom-56 md:bottom-28"
                src={beha}
                alt=""
              />
            </div>
          </div>
        ) : tab === "gov" ? (
          <div className="flex my-2 ">
            <div className="w-1/2">
              <p className="text-sm md:text-2xl mx-8 md:mx-16 h-24">
                Establish and enforce data creation policies
              </p>
              <div className="bg-[#f4f4f4] w-[150%] grid grid-cols-1 md:grid-cols-2 mt-10 px-4 py-6 md:py-10 md:px-16 gap-y-10 rounded-r-xl">
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon12} alt="" />
                  <p className="text-lg">
                    Transmit data seamlessly to your preferred data warehouse or
                    lake
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon14} alt="" />
                  <p className="text-lg">
                    Monitor model deliveries in real-time with SLAs
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon15} alt="" />
                  <p className="text-lg">
                    Seamlessly integrate with any destination via Google Tag
                    Manager Server Side
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon32} alt="" />
                  <p className="text-lg">
                    Craft data models utilizing SDKs available in numerous
                    programming languages
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/2 flex items-center justify-center">
              <img
                className="md:w-1/2 rounded-xl relative bottom-56 md:bottom-28"
                src={gove}
                alt=""
              />
            </div>
          </div>
        ) : tab === "pro" ? (
          <div className="flex my-2 ">
            <div className="w-1/2">
              <p className="text-sm md:text-2xl mx-8 md:mx-16 h-24">
                Facilitate effortless data discovery and collaboration through
                integrated workflow tools.
              </p>
              <div className="bg-[#f4f4f4] w-[150%] grid grid-cols-1 md:grid-cols-2 mt-10 px-4 py-6 md:py-10 md:px-16 gap-y-10 rounded-r-xl">
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon8} alt="" />
                  <p className="text-lg">
                    Gradually deploy your data with sandbox environments and QA
                    workflows
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon10} alt="" />
                  <p className="text-lg">
                    Implement precise access controls and single-sign-on
                    capabilities
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon16} alt="" />
                  <p className="text-lg">
                    Utilize Universal Data Language for smooth schema evolution
                    with versioning
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon18} alt="" />
                  <p className="text-lg">
                    Simplify data discovery and visualize events and entities
                    through the Tracking Catalog
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/2 flex items-center justify-center">
              <img
                className="md:w-1/2 rounded-xl relative bottom-56 md:bottom-28"
                src={prod}
                alt=""
              />
            </div>
          </div>
        ) : (
          <div className="flex my-2 ">
            <div className="w-1/2">
              <p className="text-sm md:text-2xl mx-8 md:mx-16 h-24">
                Fulfillment to dynamic regulatory requirements
              </p>
              <div className="bg-[#f4f4f4] w-[150%] grid grid-cols-1 md:grid-cols-2 mt-10 px-4 py-6 md:py-10 md:px-16 gap-y-10 rounded-r-xl">
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon11} alt="" />
                  <p className="text-lg">
                    Opt for our fully private SaaS solution to keep your data
                    within your cloud infrastructure
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon21} alt="" />
                  <p className="text-lg">
                    Collect event-based tracking essentials for streamlined,
                    compliant data modeling
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon19} alt="" />
                  <p className="text-lg">
                    Specify your data, establish access controls, and comprehend
                    the purpose behind data creation
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-[70%]">
                  <img className="w-[15%]" src={icon31} alt="" />
                  <p className="text-lg">
                    Perform comprehensive audits of your data pipeline to meet
                    regulatory standards like HIPAA, GDPR, and CCPA
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/2 flex items-center justify-center">
              <img
                className="md:w-1/2 rounded-xl relative bottom-64 md:bottom-28"
                src={full}
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Explore;
