import React from "react";
import firstimage from "../../Assets/DataCapture/Whats Possible - Marketing Performance.jpg";
import second from "../../Assets/DataCapture/Product Optimization.jpg";
import icon1 from "../../Assets/DataCapture/icons/data (1).svg";
import icon2 from "../../Assets/DataCapture/icons/data (2).svg";
import icon3 from "../../Assets/DataCapture/icons/data (3).svg";
import icon5 from "../../Assets/DataCapture/icons/data (5).svg";
import icon7 from "../../Assets/DataCapture/icons/data (7).svg";
import icon22 from "../../Assets/DataCapture/icons/data (22).svg";
import icon23 from "../../Assets/DataCapture/icons/data (23).svg";
import icon24 from "../../Assets/DataCapture/icons/data (24).svg";
import icon25 from "../../Assets/DataCapture/icons/data (25).svg";
import icon26 from "../../Assets/DataCapture/icons/data (26).svg";
import icon27 from "../../Assets/DataCapture/icons/data (27).svg";
import icon28 from "../../Assets/DataCapture/icons/data (28).svg";

const Possible = () => {
  return (
    <>
      <div className="flex flex-col relative items-center  md:items-start  lg:items-center mx-16 md:my-2 overflow-hidden">
        <h1 className="font-semibold text-xl md:text-3xl text-center my-2 w-full font-nexa ">
          <span className="text-[#145899]">What‘s</span> Possible
        </h1>
        <h1 className="font-normal text-base md:text-3xl text-center my-2 w-3/4 md:w-full font-nexa ">
          Unleash the potential of behavioral data across diverse sectors
        </h1>

        <p className="font-semibold md:font-bold text-base md:text-3xl  text-center md:text-start  md:absolute left-0  top-24 md:mt-8  font-nexa text-[#145899] hidden md:flex">
          Marketing Performance
        </p>
        <div className="flex flex-col md:flex-row-reverse items-center md:items-start my-4 overflow-hidden relative md:gap-6 ">
          <div className="flex items-center justify-center w-[70%] md:w-1/2  mt-4 md:h-48 md:mt-0 rounded-xl overflow-hidden ">
            <img
              className="md:w-[%] mx-4 md:mx-0 my-0 md:my-24 rounded-xl "
              src={firstimage}
              alt=""
            />
          </div>

          <div className="font-montserrat mt-3  md:flex md:flex-col  md:mt-16 flex flex-col items-center md:items-start  md:text-start  md:w-1/2">
            <p className="md:hidden font-semibold md:font-bold text-base md:text-3xl  text-center md:text-start  md:absolute left-0  top-24 md:mt-8  font-nexa text-[#145899] ">
              Marketing Performance
            </p>
            <p className=" text-sm md:text-xl font-normal md:ml-0 mt-2 md:mt-4 text-center md:text-start">
              Utilize detailed behavioral data to craft cohesive, precise, and
              predictive customer profiles within your data infrastructure, be
              it a warehouse, lake, or CDP.
            </p>

            <div className="aligns-center">
              {/* <button className="bg-[#48C4F4] text-white py-2 px-4 border rounded font-medium text-lg mt-4">
            Learn More
          </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 md:flex-row bg-[#] py-4  gap-4 md:gap-6 my-4 md:py-4 md:mb-8 px-8 md:px-16 bg-[#F4F4F4] ">
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          {/* <img className="w-[10%]" src={icon32} alt="" /> */}
          <img className="md:w-[10%] w-[40%]" src={icon26} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Develop custom attribution models
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon27} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Make real-time content recommendations
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon23} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Predict customer behavior & lifetime value
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon7} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Measure and map customer journeys
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon28} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Analyze content and editorial
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon22} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Forecast sales and optimize media spend
          </h1>
        </div>
      </div>
      <div className="flex flex-col relative items-center  md:items-start  lg:items-center mx-16 md:my-2 overflow-hidden">
        <div className="flex flex-col md:flex-row items-center md:justify-evenly md:items-start my-4 overflow-hidden relative md:gap-6 ">
          <div className="flex items-center justify-center w-[70%] md:w-1/2 mt-4 md:h-48 md:mt-0 rounded-xl overflow-hidden">
            <img
              className="md:w-[%] md:mx-0 mx-4 my-0 md:my-24 rounded-xl "
              src={second}
              alt=""
            />
          </div>

          <div className="font-montserrat md:px-8 md:flex md:flex-col  flex flex-col items-center md:items-start  md:text-start  md:w-1/2">
            <p className="font-bold text-lg md:text-2xl  text-center md:text-start mt-6  font-nexa text-[#145899]">
              Product Optimization
            </p>
            <p className=" text-sm md:text-xl font-normal w-full md:w-3/4  md:mt-4 text-center md:text-start">
              Improve your product journey and optimize for conversion rates,
              usage, and customer retention.
            </p>

            <div className="aligns-center">
              {/* <button className="bg-[#48C4F4] text-white py-2 px-4 border rounded font-medium text-lg mt-4">
              Learn More
            </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 md:flex-row bg-[#] py-4  gap-4 md:gap-6 my-4 md:py-4 md:mb-8 px-8 md:px-16 bg-[#F4F4F4]">
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon5} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Understand the complete customer lifecycle
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon2} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Personalize the product experience in real time
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon25} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Create a true test and learn environment
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon1} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Access data seamlessly in your preferred BI tools
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon3} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Create, own, and refine models for churn prediction and customer
            retention.
          </h1>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-3 md:mx-12">
          <img className="md:w-[10%] w-[40%]" src={icon24} alt="" />
          <h1 className="text-base md:text-sm font-light font-nexa">
            Adapt your tracking design and data
          </h1>
        </div>
      </div>
    </>
  );
};

export default Possible;
