import React from "react";
import dash from "../../Assets/DigitalTwin/About IOT Technolgy.jpg";

const About = () => {
  return (
    <div className="flex flex-col relative items-center  md:items-start  lg:items-center  md:my-10 overflow-hidden">
      <h1 className="font-semibold text-xl md:text-3xl text-center my-2 w-full font-nexa ">
        <span className="text-[#145899]">Abo﻿ut </span> IoT Technology
      </h1>
      <h1 className="font-normal text-base md:text-xl text-center my-2 w-3/4 md:w-[74%] font-nexa ">
        The Internet of Things is a newly discovered technology that has rapidly
        entered the world of business and seized it. According to Gartner, in
        2019 alone, 14.2 billion connected things were used, and by 2025 this
        number is estimated to rise to 25 billion connected things. This shows
        that the mass adoption of IoT applications has not only proved their
        usefulness but has become one of the proliferating trends in the advance
        of information technologies. Today we will tell you about IoT Analytics.
      </h1>

      <div className="flex flex-col md:flex-row-reverse items-center md:items-start my-4 overflow-hidden relative ">
        <img src={dash} alt="" />
      </div>
    </div>
  );
};

export default About;
