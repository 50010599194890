import React from "react";
import HeadImg from "../Assets/DigitalTwin/Self Managed - Digital Twin.jpg";
import Reviews from "../Components/IndexComponents/Reviews";
import MediumArticles from "./Admin/MediumArticles";
import Intro from "../Components/DigitalTwinComponents/Intro";
import Workflow from "../Components/DigitalTwinComponents/Workflow";
import Usecases from "../Components/DigitalTwinComponents/Usecases";
import Features from "../Components/DigitalTwinComponents/Features";
import ShowCase from "../Components/DigitalTwinComponents/ShowCase";
import About from "../Components/DigitalTwinComponents/About";

const DigitalTwinPage = () => {
  return (
    <div className="flex flex-col gap-4 md:gap-2 mt-12 font-montserrat">
      <div className=" relative z-20">
        <div className="absolute top-0 w-full h-full bg-gradient-to-b from-transparent via-transparent to-[#fff]  flex flex-col items-center justify-center z-20"></div>
        <div className="flex flex-col w-full md:w-fit absolute z-20 top-36 md:left-16 md:top-28">
          <h1 className="text-[22px] font-bold  lg:text-xl  gap-2  md:my-0 md:mx-0 mx-4 font-nexa text-[#135788]">
            Self Managed
          </h1>
          <h1 className="text-lg font-bold  lg:text-4xl  gap-2  md:my-0 md:mx-0 mx-4 font-nexa text-[#2F2F2F]">
            Digital Twin
          </h1>
        </div>
        <div className="w-full  overflow-hidden relative z-10">
          <img className="object-contain " src={HeadImg} alt="" />
        </div>
      </div>
      <div className="flex flex-col gap-10 ">
        <Intro />
        <Workflow />
        <div className="mx- ">
          <Usecases />
        </div>
        <Features />
        <div className="mx- ">
          <ShowCase />
        </div>
      </div>

      {/* <Overview />
      <Possible />
      <Explore />
      <Offerings /> */}
      <Reviews />
      <MediumArticles />
      <About />
    </div>
  );
};

export default DigitalTwinPage;
