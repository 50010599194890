import React from "react";
import ico1 from "../../Assets/DigitalTwin/icons/1.svg";
import ico2 from "../../Assets/DigitalTwin/icons/2.svg";
import ico3 from "../../Assets/DigitalTwin/icons/3.svg";
import ico4 from "../../Assets/DigitalTwin/icons/4.svg";
import ico5 from "../../Assets/DigitalTwin/icons/5.svg";
import ico6 from "../../Assets/DigitalTwin/icons/6.svg";
const Usecases = () => {
  return (
    <>
      <div className="flex flex-col items-center my-4 mb-8 mx-16 ">
        <p className="text-3xl text-center  font-semibold font-nexa flex">
          <span className="mx-2 text-[#135788]">Major Use </span>
          Cases
        </p>
        <p className=" text-sm md:text-xl font-light md:ml-0 mt-2 md:mt-4 md:mb-8 text-center w-3/4">
          Digital Twin A living digital representation of a physical system that
          is dynamically updated mimic the structure, state, and behavior of the
          physical system to drive business outcomes
        </p>

        <div className="flex flex-col md:flex-row bg-[#] py-4 md:pt-4 gap-4 md:gap-0 my-4 md:py-4 ">
          <div className="flex flex-col items-center gap-3 mx-16 md:w-1/3 md:justify-start ">
            <img className="w-[25%]" src={ico3} alt="" />
            <h1 className="text-base md:text-xl font-semibold font-nexa text-center">
              Improve Field Operations In Manufacturing Plants
            </h1>
            <p className="text-center text-xs md:text-base font-medium mx- md:mx-2  text-[#00000099] font-montserrat">
              Swiftly identify and resolve equipment and process irregularities
              on the plant floor to enhance worker productivity and operational
              efficiency
            </p>
            <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
          </div>
          <div className="flex flex-col items-center gap-3 mx-16 md:w-1/3 md:justify-start ">
            <img className="w-[25%]" src={ico2} alt="" />
            <h1 className="text-base md:text-xl font-semibold font-nexa text-center">
              Enhance Equipment Uptime In Remote Facilities
            </h1>
            <p className="text-center text-xs md:text-base font-medium mx- md:mx-2  text-[#00000099] font-montserrat">
              Quickly troubleshoot equipment problems remotely by accessing all
              pertinent operational data for swift decision-making
            </p>
            <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
          </div>
          <div className="flex flex-col items-center gap-3 mx-16  md:w-1/3 md:justify-start ">
            <img className="w-[25%]" src={ico1} alt="" />
            <h1 className="text-base md:text-xl font-semibold font-nexa text-center">
              Improve Public Experience Within Commercial Properties
            </h1>
            <p className="text-center text-xs md:text-base font-medium mx- md:mx-2 text-[#00000099] font-montserrat">
              Track real-time and past temperature, occupancy, and air-quality
              data in indoor and outdoor areas for enhanced occupant comfort
            </p>
            <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center my-4 mx-16 ">
        <p className="text-3xl text-center  font-semibold font-nexa flex">
          <span className="mx-2 text-[#135788]">Key </span>
          Offerings
        </p>
        <p className=" text-sm md:text-lg font-light md:ml-0 mt-2 md:mt-4 md:mb-8 text-center w-3/4">
          Optimize operations by easily creating digital twins of real-world
          systems
        </p>

        <div className="flex flex-col md:flex-row bg-[#] py-4 md:pt-4 gap-4 md:gap-0 my-4 md:py-4 md:mx-6 ">
          <div className="flex flex-col items-center gap-3 mx-16 md:w-1/3 md:justify-start ">
            <img className="w-[25%]" src={ico4} alt="" />
            <h1 className="text-base md:text-xl font-semibold font-nexa text-center">
              Immersive 3D Views
            </h1>
            <p className="text-center text-xs md:text-base font-medium mx- md:mx-2  text-[#00000099] font-montserrat">
              Swiftly identify and resolve equipment and process irregularities
              on the plant floor to enhance worker productivity and operational
              efficiency
            </p>
            <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
          </div>
          <div className="flex flex-col items-center gap-3 mx-16 md:w-1/3 md:justify-start ">
            <img className="w-[25%]" src={ico5} alt="" />
            <h1 className="text-base md:text-xl font-semibold font-nexa text-center">
              Physical Environment Modeling
            </h1>
            <p className="text-center text-xs md:text-base font-medium mx- md:mx-2  text-[#00000099] font-montserrat">
              An automatically generated knowledge graph seamlessly links your
              data sources to virtual representations of physical systems,
              ensuring precise replication of real-world scenarios.
            </p>
            <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
          </div>
          <div className="flex flex-col items-center gap-3 mx-16  md:w-1/3 md:justify-start ">
            <img className="w-[25%]" src={ico6} alt="" />
            <h1 className="text-base md:text-xl font-semibold font-nexa text-center">
              Easy Access to Data
            </h1>
            <p className="text-center text-xs md:text-base font-medium mx- md:mx-2 text-[#00000099] font-montserrat">
              Data accessibility is seamless, irrespective of storage location,
              facilitated by AWS IoT TwinMaker for swift retrieval from diverse
              sources.
            </p>
            <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Usecases;
