import React, { useState, useEffect } from "react";
import BlogCard from "../../Components/BlogCard";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";

const MediumArticles = ({ numberOfArticles }) => {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@cortezd334`
    )
      .then((res) => res.json())
      .then((data) => setArticles(data.items.slice(0, numberOfArticles)));
  }, [numberOfArticles]);

  const next = () => {
    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    setCurrentPage(currentPage - 1);
  };

  const displayedArticles = articles.slice(
    currentPage * 3,
    currentPage * 3 + 3
  );
  const displayedArticlesMobile = articles.slice(
    currentPage * 3,
    currentPage * 3 + 1
  );

  return (
    <>
      <div className="mt-8 text-[#2F2F2F]">
        <h1 className="text-[22px] font-bold text-center lg:text-[34px] font-nexa ">
          <span className="text-[#135788]">Blogs</span> we read
        </h1>
      </div>
      <p className="mx-8 mt-0 opacity-60 font-normal text-[16px] text-center xl:mx-[30rem] font-montserrat">
        See how we have helped our clients improve their decision making by
        establishing a data management infrastructure
      </p>
      {/* <div className="hidden md:flex flex-wrap gap-8 justify-center py-12 relative">
        {displayedArticles.map((article, index) => (
          <div key={index} className="">
            <BlogCard article={article} />
          </div>
        ))}
        <button
          onClick={prev}
          disabled={currentPage === 0}
          className="text-[#135788] py-2 px-4 rounded absolute left-0 top-1/2 transform -translate-y-1/2"
        >
          <GrPrevious className="text-3xl" />
        </button>
        <button
          onClick={next}
          disabled={currentPage === Math.ceil(articles.length / 3) - 1}
          className="text-[#135788] font-bold py-2 px-4 rounded absolute right-0 top-1/2 transform -translate-y-1/2"
        >
          <GrNext className="text-3xl" />
        </button>
      </div> */}
      <div className="flex gap-16 items-center  justify-center py-12 relative md:mx-32">
        <Swiper
          slidesPerView={3}
          breakpoints={{
            0: {
              slidesPerView: 1,
              navigation: false,
            },
            400: {
              slidesPerView: 1,
              navigation: false,
            },
            639: {
              slidesPerView: 1,
              navigation: false,
            },
            865: {
              slidesPerView: 3,
              navigation: true,
            },
          }}
          spaceBetween={30}
          // navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {articles.map((article, index) => (
            <SwiperSlide>
              <div key={index} className=" flex justify-center ">
                <BlogCard article={article} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* <div className="md:hidden flex flex-wrap py-12 relative justify-center px-6">
        {displayedArticlesMobile.map((article, index) => (
          <div key={index} className="">
            <BlogCard article={article} />
          </div>
        ))}
        <button
          onClick={prev}
          disabled={currentPage === 0}
          className="text-[#135788] py-2 rounded absolute left-6 top-1/2 transform -translate-x-3/4"
        >
          <GrPrevious className="text-3xl" />
        </button>
        <button
          onClick={next}
          disabled={currentPage === Math.ceil(articles.length / 3) - 1}
          className="text-[#135788] font-bold py-2 rounded absolute right-6 top-1/2 transform translate-x-3/4"
        >
          <GrNext className="text-3xl" />
        </button>
      </div> */}
    </>
  );
};

export default MediumArticles;
