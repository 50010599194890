import React, { useState } from "react";
import one from "../../Assets/DigitalTwin/Digital Twin Showcase - img 1.jpg";
import two from "../../Assets/DigitalTwin/Digital Twin Showcase - img 2.jpg";
import three from "../../Assets/DigitalTwin/Digital Twin Showcase - img 3.jpg";
import four from "../../Assets/DigitalTwin/Digital Twin Showcase - img 4.jpg";
import five from "../../Assets/DigitalTwin/Digital Twin Showcase - img 5.jpg";
import six from "../../Assets/DigitalTwin/Digital Twin - img 5 .jpg";

const ShowCase = () => {
  const [showImg, setShowImg] = useState(false);
  const [pre, setPre] = useState("");

  return (
    <>
      {showImg ? (
        <div
          onClick={() => setShowImg(!showImg)}
          className="h-[100vh] w-[100vw] z-30 fixed top-0 flex items-center justify-center  bg-[#00000040]"
        >
          <div className="w-[90%] h-[90%] object-contain flex items-center justify-center">
            <img className="h-full " src={pre} alt="" />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="flex flex-col items-center my-4 mb-8 ">
        <p className="text-3xl text-center mx-8 my-2 relative bottom-6 font-bold font-nexa flex">
          <span className="mx-2 text-[#135788]">Show </span>
          Cases
        </p>
        <div className="flex flex-col gap-1 justify-start mx-4 w-[92%]  overflow-hidden">
          <div className="flex gap-1  ">
            <img
              className="w-[40%] cursor-pointer"
              onClick={() => {
                setPre(one);
                setShowImg(!showImg);
              }}
              src={one}
              alt=""
            />
            <img
              onClick={() => {
                setPre(two);
                setShowImg(!showImg);
              }}
              src={two}
              className="w-[40%] cursor-pointer"
              alt=""
            />
            <img
              onClick={() => {
                setPre(three);
                setShowImg(!showImg);
              }}
              src={three}
              className="w-[20%] cursor-pointer"
              alt=""
            />
          </div>
          <div className="flex gap-1 ">
            <img
              onClick={() => {
                setPre(four);
                setShowImg(!showImg);
              }}
              className="w-[50%] cursor-pointer"
              src={four}
              alt=""
            />
            <img
              onClick={() => {
                setPre(six);
                setShowImg(!showImg);
              }}
              src={six}
              className="w-[50%] cursor-pointer"
              alt=""
            />
          </div>
          <div className="flex gap-1 ">
            <img
              onClick={() => {
                setPre(five);
                setShowImg(!showImg);
              }}
              className="w-[100%] cursor-pointer"
              src={five}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowCase;
