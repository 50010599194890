import React from "react";
import ReviewCard from "./ReviewCard";
import Review from "../../Data/Review.js";
import MarqueeQ from "../magicui/marq.tsx";

const Reviews = () => {
  const createReview = (piece) => {
    return (
      <ReviewCard
        key={piece.id}
        img={piece.img}
        name={piece.name}
        location={piece.location}
        review={piece.review}
      />
    );
  };
  return (
    <div className="font-montserrat">
      <h1 className="text-[22px] font-bold text-center md:text-[34px] mt-8 font-nexa">
        <span className="text-[#135788] mx-2">Customer</span>
        Testimonials
      </h1>
      <p className="text-sm md:text-[16px] font-normal text-center leading-[22px] tracking-[0.48px] opacity-60 mx-8 md:my-2 font-montserrat">
        These are the stories of our customers who have joined us with great
        pleasure when using this crazy feature.
      </p>
      <div>
        <MarqueeQ />
      </div>
      {/* <div className="flex flex-col items-center xl:flex-row xl:justify-center md:gap-8">
        {Review.map(createReview)}
      </div> */}
    </div>
  );
};

export default Reviews;
