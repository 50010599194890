import React from "react";
import icon6 from "../../Assets/DataCapture/icons/data (6).svg";
import icon13 from "../../Assets/DataCapture/icons/data (13).svg";
import icon17 from "../../Assets/DataCapture/icons/data (17).svg";
import icon29 from "../../Assets/DataCapture/icons/data (29).svg";
import icon33 from "../../Assets/DataCapture/icons/data (33).svg";

const Offerings = () => {
  return (
    <div className="flex flex-col items-center my-4 ">
      <p className="text-3xl text-center mx-8 font-semibold font-nexa flex">
        <span className="mx-2 text-[#135788]">Key</span>
        Offerings
      </p>
      <p className=" text-sm md:text-lg font-light md:ml-0 mt-2 md:mt-4 text-center w-3/4">
        Snowplow acts as a powerful and flexible data collection platform that
        funnels data from various sources, transforms it into a usable format,
        and empowers you to own and analyze the information for deeper insights.
      </p>

      <div className="flex flex-col md:flex-row bg-[#] py-4 md:pt-4 gap-4 md:gap-0 my-4 md:py-4 mx-16 ">
        <div className="flex flex-col items-center gap-3 md:mx-1 md:w-1/5 md:justify-start ">
          <img className="w-[25%]" src={icon29} alt="" />
          <h1 className="text-base md:text-xl font-bold font-nexa text-center">
            Flexibility
          </h1>
          <p className="text-center text-xs  font-medium mx-16 md:mx-2  text-[#00000099] font-montserrat">
            Snowplow is known for its flexibility. It allows you to capture a
            wide range of data types and customize what information you collect.
            This lets you tailor data collection to your specific needs.
          </p>
          <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
        </div>
        <div className="flex flex-col items-center gap-3 md:mx-1 md:w-1/5 md:justify-start ">
          <img className="w-[25%]" src={icon17} alt="" />
          <h1 className="text-base md:text-xl font-bold font-nexa text-center">
            Extensibility
          </h1>
          <p className="text-center text-xs  font-medium mx-16 md:mx-2  text-[#00000099] font-montserrat">
            Snowplow offers extensive extensibility through enriched data
            sources and custom integrations. You can add additional data points
            to your collected information, enriching the overall picture.
          </p>
          <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
        </div>
        <div className="flex flex-col items-center gap-3 md:mx-1  md:w-1/5 md:justify-start ">
          <img className="w-[25%]" src={icon33} alt="" />
          <h1 className="text-base md:text-xl font-bold font-nexa text-center">
            Data Pipeline
          </h1>
          <p className="text-center text-xs  font-medium mx-16 md:mx-2 text-[#00000099] font-montserrat">
            Acts as a data pipeline that takes in info from different places via
            HTTP requests and turns it into organized data. This info could be
            from websites or apps, like clicks, page views, or other events.
          </p>
          <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
        </div>
        <div className="flex flex-col items-center gap-3 md:mx-1 md:w-1/5 md:justify-start ">
          <img className="w-[25%]" src={icon6} alt="" />
          <h1 className="text-base md:text-xl font-bold font-nexa text-center">
            Data Ownership
          </h1>
          <p className="text-center text-xs  font-medium mx-16 md:mx-2  text-[#00000099] font-montserrat">
            Unlike some analytics services, Snowplow provides you with complete
            ownership of your data. It stores the raw data, allowing for further
            analysis and customization beyond the platform's basic
            functionalities.
          </p>
          <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" />
        </div>
        <div className="flex flex-col items-center gap-3 md:mx-1 md:w-1/5 md:justify-start ">
          <img className="w-[25%]" src={icon13} alt="" />
          <h1 className="text-base md:text-xl font-bold font-nexa text-center">
            Focus on Unstructured Data
          </h1>
          <p className="text-center text-xs  font-medium mx-16 md:mx-2  text-[#00000099] font-montserrat">
            Snowplow is particularly adept at handling unstructured data. This
            includes things like user behavior patterns, clickstreams, and other
            detailed information beyond simple metrics.
          </p>
          {/* <hr className="w-2/5 mt-1 drop-shadow-md border-[#00000050] md:hidden" /> */}
        </div>
      </div>
    </div>
  );
};

export default Offerings;
