import React from "react";
import cloud from "../../Assets/DigitalTwin/Digital Twin Showcase - img 4.jpg";

const Intro = () => {
  return (
    <div className="flex flex-col md:mx-20 font-montserrat">
      <div className="flex flex-col">
        <p className="text-lg md:text-3xl text-center md:text-start font-semibold  my-4 mb-8">
          Introduction to{" "}
          <span className="text-[#135788] gap-2">Digital Twin </span>
        </p>
        <div className="flex gap-4 flex-col  font-normal md:font-light md:text-xl text-base text-start ">
          <p className="">
            <span className="text-[#135788] font-semibold mx-2">
              Delphi Digital Twin
            </span>
            is a platform designed to make digital twins accessible to everyone
            and easily create digital twins of real-world systems to optimize
            operations. With digital twin, you can easily combine sensors, IoT
            data, equipment, production lines, and live videos into a
            consolidated digital version of operations.
          </p>
          <p>
            We can create immersive 3D visualizations of real-world environments
            by importing existing 3D modules directly into the service. It
            allows your operator to make more informed decisions in less time.
            Using Delphi digital twin can bring new life to an existing
            business.
          </p>
          <div className="overflow-hidden h-32 md:h-96 rounded-xl flex items-center md:items-">
            <img src={cloud} className="rounded-xl" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
